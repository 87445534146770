import React,{FC, ReactNode} from "react";
import { Row,Table, Spinner, Alert } from 'react-bootstrap';
import { User } from './User';
import { useGetUsersQuery } from '../store/api/usersApi'
import { selectSearch } from '../store/slices/usersSlice'
import { useSelector } from "react-redux";
import { UserSearch } from "./UserSearch";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

interface User {
    login:string
}
export const  UsersScreen:FC=()=> {
    const search = useSelector(selectSearch)
    const { data: usersData, isSuccess: isSuccessGetUsers, isLoading: isLoadingGetUsers, error: errorUsers } = useGetUsersQuery({ search: search,limit:7 });

    let content: FetchBaseQueryError | SerializedError | React.ReactNode
    if (isLoadingGetUsers) {
        content = <Spinner />
    }
    else if (isSuccessGetUsers) {
        content =<Table   hover size="sm"><tbody>{usersData.map((user: { login: React.Key | null | undefined; }) => (
            <User key={user.login} user={user} />
        ))
    }
    </tbody>
        </Table>
    } else {
        content = errorUsers ?? <Alert>{errorUsers}</Alert>
    }

    return (
        <>
            <Row className="my-2">
                <UserSearch />
            </Row>
            <Row>
                {content}
            </Row>
        </>
    )
}