import React, { useEffect, useState } from "react"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { statusConfig } from "./EnumsCustom";
import { Alert, Card, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useGetTicketQuery, useUpdateTicketMutation } from '../store/api/mainApi';
import { useGetCategoriesQuery } from '../store/api/categoriesApi';
import { RSelect1, mapDataForSelects, mapDataFromSelectToOpiekun } from "./RSelect1";
import AsyncSelectUsers from "./AsyncSelectUsers";
export function TicketEditForm() {
    const { id: ticketId } = useParams();
    const navigate = useNavigate()
    const [updateTicket] = useUpdateTicketMutation()
    const { data: ticketdata, isSuccess: isSuccessGetTicket, isLoading: isLoadingGetTicket, error: errorTicket, isError: isErrorTicket } = useGetTicketQuery(ticketId);
    const { data: categoriesData, isSuccess: isSuccessGetCategories, isLoading: isLoadingGetCategories } = useGetCategoriesQuery();
    const [newTicket, setNewTicket] = useState({})

    useEffect(() => {
        if (isSuccessGetTicket) {
            const { id, CategoryId, status, zglaszajacyId, temat, tresc } = ticketdata
            const opiekunowieOld = ticketdata?.opiekunowie.map(opiekun => opiekun.login)
            setNewTicket({ id, CategoryId, status, opiekunowie: opiekunowieOld, zglaszajacyId, temat, tresc })
        }
    }, [isSuccessGetTicket])


    const handleInputChange = (e, input) => {

        let value, name, target;
        target = e?.target ? e.target : e;
        if (Array.isArray(e)) {
            name = input
            value = e.map(item => item.value)
        }
        else {
            value = target.type === 'checkbox' ? target.checked : target.value;
            name = input ? input : target.name
        }
        console.log(name, value);
        setNewTicket({
            ...newTicket,
            [name]: value,
        });

    };



    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            console.log(newTicket)
            await updateTicket(newTicket).unwrap()
                .then((data) => {
                    if (data.message === 'OK') {
                        navigate('/tickets');
                    } else {
                        console.log("nie udało się zapisać");
                    }
                })



        } catch (error) {
            console.error(error);
        }
    }

    let content, optionsKategorie, defaultZglaszajacy, defaultCategory, selectCategory;
    if (isLoadingGetTicket || isLoadingGetCategories) {
        content = <Spinner />
    } else if (isSuccessGetTicket && isSuccessGetCategories) {
        defaultZglaszajacy = ticketdata?.zglaszajacy?.name + " " + ticketdata?.zglaszajacy?.surname + "(" + ticketdata?.zglaszajacy?.login + ")";

        defaultCategory = ticketdata?.Category?.name



        optionsKategorie = mapDataForSelects(categoriesData, { value: item => item.id, name: item => item.name })

        selectCategory = <RSelect1 onChange={handleInputChange} options={optionsKategorie} defaultInputValue={defaultCategory} inputName="CategoryId" />;
        content =
            <Card className="shadow m-2">
                <Card.Header><b>Edytuj zgłoszenie</b></Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Kategoria</Form.Label>
                            {selectCategory}
                            <Form.Label>Temat</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Temat"
                                name="temat"
                                value={newTicket?.temat || ''}
                                onChange={handleInputChange}

                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Zglaszający</Form.Label>
                            <AsyncSelectUsers onChange={handleInputChange}
                                defaultInputValue={defaultZglaszajacy}
                                inputName="zglaszajacyId" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>opiekunowie</Form.Label>
                            <AsyncSelectUsers onChange={handleInputChange}
                                defaultValue={ticketdata?.opiekunowie.map((opiekun) => {
                                    return ({ value: opiekun.login, label: opiekun.name + " " + opiekun.surname })
                                })} isMulti={true}
                                inputName="opiekunowie" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>status</Form.Label>
                            <RSelect1 onChange={handleInputChange} options={mapDataForSelects(statusConfig, { value: item => item, name: item => item })} defaultInputValue={ticketdata?.status} inputName="status" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>tresc</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="tresc"
                                name="tresc"
                                value={newTicket?.tresc || ''}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Button title="Zapisz" variant="dark" onClick={(e) => handleSubmit(e)} > Zapisz
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
    }
    else if (isErrorTicket) {
        content = errorTicket ?? <Alert>errorTicket</Alert>
    }
    return (
        <>

            {content}
        </>

    )
}