import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation } from 'react-router-dom';

function BreadCrumbs() {
    const location = useLocation()
    const currPath = location.pathname
    const breadCrumbPaths = [
        { label: "Strona Główna", url: '/dashboard' },
        { label: "Zgłoszenia", url: '/tickets' },
        { label: "Podgląd zgłoszenia", url: '/tickets/[0-9]' },
        { label: "Edycja Zgłoszenia", url: '/tickets/edit/[0-9]' },
        { label: "Użytkownicy", url: '/users' },
        { label: "Profil Użytkownika", url: '/users/[a-z]' },
    ]
    const matchedPaths = breadCrumbPaths.map(x => {
        if (currPath.match(x.url)) {
            return x
        }
    })
    const returnPaths = matchedPaths.filter(x => x?.url !== undefined);
    let firstRenderedBreadCrumb, secondRenderedBreadCrumb, thirdRenderedBreadCrumb
    if (returnPaths[0]?.url.match(breadCrumbPaths[0]?.url)) {
        firstRenderedBreadCrumb = ""
    } else {
        firstRenderedBreadCrumb = <Breadcrumb.Item href={breadCrumbPaths[0]?.url}>{breadCrumbPaths[0]?.label}</Breadcrumb.Item>
    }
    if (returnPaths.length === 1) {
        secondRenderedBreadCrumb = <Breadcrumb.Item active href={returnPaths[0]?.url}>{returnPaths[0]?.label}</Breadcrumb.Item>
    } else {
        secondRenderedBreadCrumb = <Breadcrumb.Item href={returnPaths[0]?.url}>{returnPaths[0]?.label}</Breadcrumb.Item>
        thirdRenderedBreadCrumb = <Breadcrumb.Item active href={returnPaths[1]?.url}>{returnPaths[1]?.label}{" "}:{" "}{currPath.split("/").slice(-1)}</Breadcrumb.Item>
    }
    return (
        <>
            <Breadcrumb>
                {firstRenderedBreadCrumb}
                {secondRenderedBreadCrumb}
                {thirdRenderedBreadCrumb}
            </Breadcrumb>
        </>
    )
}
export default BreadCrumbs