import React, { useState } from "react"
import { Badge, Button, Col, Form, Stack, Spinner, Card, Toast, ToastContainer, Row } from "react-bootstrap";
import { useAddAcceptTicketMutation, useUpdateTicketStatusMutation } from "../store/api/mainApi";
import { useGetUsersQuery } from "../store/api/usersApi";
import RSelect1, { mapDataForSelects } from "./RSelect1";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AsyncSelectUsers from "./AsyncSelectUsers";



export const TicketAddAccept = () => {
    const account = useSelector(state => state.auth.loggedUser);
    const { id } = useParams()
    const [showToast, setShowToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const navigate = useNavigate()
    const [userAcceptId, setUserAcceptId] = useState("")
    const [ticketAcceptId] = useState(id)
    const [addAccept] = useAddAcceptTicketMutation()
    const [updateTicketStatus] = useUpdateTicketStatusMutation();


    const handleOnSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await addAccept({ status: "Do Akceptacji", userAcceptId, ticketAcceptId }).unwrap()
            await updateTicketStatus({ id: ticketAcceptId, status: "Wstrzymane" }).unwrap()
            const { success, message } = response;
            if (success) {
                navigate(`/tickets/${id}`)
            } else {
                setShowToast(true)
                setMessageToast(message)
            }

        } catch (error) {
            console.log(error);
        }
    }
    let content, toast
    toast =
        <ToastContainer position="top-center">
            <Toast onClose={() => setShowToast(false)} show={showToast} delay={2000} autohide>
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">Komunikat</strong>
                </Toast.Header>
                <Toast.Body>{messageToast}</Toast.Body>
            </Toast>
        </ToastContainer>
    content = <>
        <Form onSubmit={handleOnSubmit}>
            <Row>
                <Col sm={6} md={6} lg={6} xs={6} xl={6} xxl={6}  >
                    <AsyncSelectUsers onChange={(e) => setUserAcceptId(e.value)} />
                </Col>
                <Col sm={6} md={6} lg={6} xs={6} xl={6} xxl={6}  >
                    <Button className="col-12" variant="dark" type="submit">Dodaj Akceptację</Button>
                </Col>
            </Row>
        </Form>
    </>
    return (
        <>
            {showToast && toast}
            {content}
        </>

    )
}